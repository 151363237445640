import React, { useState, useEffect } from 'react'
import {ReactSVG} from 'react-svg'
import iconCrossRed from '../images/icon-cross-red.svg'
import iconCheckGreen from '../images/icon-check-green.svg'
import OpenDoor from '../gql/open_door.js'
import {useMutation} from '@apollo/client'
import {removeCookie} from '../tools/cookie.js'
import { useTranslation } from 'react-i18next'
import * as Sentry from "@sentry/react"
import timers from '../timers.js'

const Device = (props) => {
  const { t } = useTranslation()
  const [deviceState, setDeviceState] = useState("unset")

  const [setTargetState] = useMutation(OpenDoor, {
    context: {
      headers: {'X-Sensorberg-Kiosk-Client-Token': props.kioskClientToken}
    },

    onCompleted: (data) => {

    const state = data.requestActuatorAction === null ? 'error' : data.requestActuatorAction.actingRequest.state

    if ((state === 'approved') || (state === 'success')) {
      setDeviceState("success")
      setTimeout(() => {setDeviceState("unset")}, timers.responseBannerDuration)
    }
    else {
      setDeviceState("error")
      setTimeout(() => {setDeviceState("unset")}, timers.responseBannerDuration)
      Sentry.captureException(`[kiosk ${props.config.name}] Could not open "${props.device.name}" with ${JSON.stringify(data)}`)
    }

    const triggerAutoLogout = (reason) => {
      window.logoutAfterRequestTimer = setTimeout(() => {
        props.onChangeComponentState("getconfigaccesstoken")
        console.log(`%c[AUTO LOGOUT] Triggered due to ${reason}`, 'background-color: #8933df; color: white')
        removeCookie('personalBearerToken')
      }, timers.logoutAfterAutoOpenDuration)
    }

    switch (true) {
      case (props.autoOpen && props.logoutAfterRequest): triggerAutoLogout("autoOpen AND logoutAfterRequest"); break
      case (props.autoOpen): triggerAutoLogout("autoOpen"); break
      case (props.logoutAfterRequest): triggerAutoLogout("logoutAfterRequest"); break
      default:
    }
  }})

  const handleClick = () => {
    setDeviceState("loading")
    setTargetState({variables: {id: props.device.id}})
  }

  useEffect(() => {
    if ((props.autoOpen) && (deviceState === "unset")) {
      // todo: refactor DRY stuff from handleClick()
      setTargetState({variables: {id: props.device.id}})
      console.log("%c[DEVICES] autoOpen requested from device", 'background: #007acc; color: #fff')
      console.log("%c[DEVICES] autoOpen requested from device", 'background: #007acc; color: #fff')
      setDeviceState("loading")
    }
  }, [props, setDeviceState, setTargetState, deviceState])

  return <div className={`
      text-blue-800
      ${deviceState === "unset" && "bg-white"}
      ${deviceState === "loading" && "bg-grey-200"}
      ${deviceState === "error" && "bg-red-300"}
      ${deviceState === "success" && "bg-green-300"}
      ${deviceState === "loading" ? "justify-center" : "justify-start"}
      border-b border-blue-400
      sm:h-40
      px-3 py-2 sm:px-6
      flex items-center
      cursor-pointer`}
      onClick={() => deviceState === "unset" && handleClick()}
      data-testid={props.device.id}
      data-test-auto-open={props.autoOpen}
    >
      {deviceState === "unset" &&
        <div className="flex items-center">
          <i className={`${props.type === "locker" && "icon-locker"} ${props.type === "door" && "icon-door"} text-2xl sm:text-5xl mr-4 sm:mr-8`}></i>
          <p className="flex-1 sm:mr-10 sm:text-lg">{props.device.name}</p>
        </div>
      }
      {deviceState === "success" &&
        <div className="flex items-center" data-testid="success">
          <ReactSVG
            beforeInjection={(svg) => { svg.classList.add('max-w-full') }}
            className="ml-8 mr-16"
            style={{width: '4rem', flex: '1 0 auto'}}
            src={iconCheckGreen}
          />
          <p className="text-lg" dangerouslySetInnerHTML={{__html: t('device.success_headline_html', {deviceName: props.device.name})}} />
        </div>
      }
      {deviceState === "error" &&
        <div className="flex items-center">
          <ReactSVG
            beforeInjection={(svg) => { svg.classList.add('max-w-full') }}
            className="ml-8 mr-16"
            style={{width: '4rem', flex: '1 0 auto'}}
            src={iconCrossRed}
          />
          <p className="text-lg" dangerouslySetInnerHTML={{__html: t('device.error_headline_html', {deviceName: props.device.name})}} />
        </div>
      }
      {deviceState === "loading" &&
        <i className="loading-icon loading-icon--xl absolute" data-testid="success"></i>
      }
  </div>
}

export default Device
